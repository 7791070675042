// import logo from './logo.svg';
import './App.css';
// import Page from './Component/LandingPage/Page';
import Layout from './Component/Layout/Layout';

function App() {
  return (
    <div className="App">
  <Layout/>
    </div>
  );
}

export default App;
